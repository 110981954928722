.display-with-remove-button{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.fill-remaining-space{
  flex-grow: 1
}

.remove-button{
  align-self: center;
}