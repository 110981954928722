.agenda-container{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.agenda-container.large-screen{
  flex-basis: 200px;
}

.large-screen > .agenda-catalog{
  flex: 1.75;
}

.large-screen > .current-list-container{
  flex: 1;
}

.small-screen > div{
  width: 100%;
}

.small-screen * .current-list-container{
  margin-left: 0;
}

/* Style tabs */
.agenda-tab-menu{
  display: flex;
  flex-flow: row nowrap;
  
  /* dont let tab menu scroll off screen */
  position: sticky;
  top: -3em;
  z-index: 100;
}

.agenda-tab-menu > .active{
  border-bottom: 3px solid #2185d0;
}

.agenda-tab-menu > .item{
  flex: 1 !important;
  flex-shrink: 1 !important;
  align-content: center;
  justify-content: center;
  font-size: 1.25rem;
  font-family: 'Bowlby One SC', sans-serif;

}

.tab-title{
  margin-right: .5em; 
}
/* Style tab panels on small screens */
.small-screen * .ui.segment{
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  background: none;
}

.small-screen * .ui.menu .item > .label{
  margin-left: 0 !important
}