.list-numbers-display{
  display: flex;
  float: 1;
  justify-content: center;
  font-family: Bowlby One SC; 
}

.list-numbers-display > div {
  font-family: Bowlby One SC !important;
  font-weight: 100 !important;
  font-size: 1em !important;
}