/* Individual item */
.bm-item {
  display: block;

  font-size: 2rem;
  font-family: 'Bowlby One SC', sans-serif;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 1.5rem;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: goldenrod;
}
.bm-item:focus {
  outline: none;
}

.bm-active-item{
  color: #3892c7;
}

/* The rest copied directly from react-burger-menu docs */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 1em;
  top: 1em;
  background: lightgrey;
  border: 5px solid lightgray;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}