.home-page-container{
  display: flex;
  flex-flow: column nowrap;
  height: fit-content;
}
.home-announcements{
  margin-top: 3rem;
  background: rgba(211, 211, 211, 0.85);
  border: 2px solid black;
  padding: 10px;
  border-radius: 5px;
}

.monpoc-disclaimer{
  padding: 1rem;
}

.home-col1{
  padding-left: 2em !important;
}

.home-col2{
  padding-right: 2em !important;
}

.link-buttons{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.other-home-links{
  text-align: center;

  font-size: 1.3rem;
  line-height: 1.3rem;
  font-family: 'Bowlby One SC', sans-serif;
  color: black;
  padding:1vmin;

  border:solid 2px #000;
  box-shadow:0 6px 6px -6px #000;
  
  width: 300px;

  padding: 15px;
  margin: 5px;
  background-image:radial-gradient(circle, #EAFFBF, #9BC53D);
}

.kofi-button{
  text-align: center;
}

.kofi-button > a:hover{
  filter: brightness(120%); 
}