/* media queries */
@media screen and (min-width: 0px) and (max-width: 600px) {
  .health-box { font-size: 1.15rem; }  
  .go-alpha, .go-hyper { font-size: 1.15rem};
}

@media screen and (min-width: 601px) {
  .health-box { font-size: 1.5rem; }  
  .go-alpha, .go-hyper { font-size: 1.5rem};

}

.health-track-container{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  padding: 5px;
}

.health-box{
  font-family: 'Open Sans';
  /* border: 2px solid black; */
  flex-grow: 1;
  text-align: center;
  margin: 0 5px 0 5px;
}

.go-alpha, .go-hyper{
  border: 2px solid black;
  font-family: 'Bowlby One SC', sans-serif;
  cursor: pointer;
}

.go-hyper{
  background: #daa520;
  animation: hyperShift 3s infinite;
}

.go-alpha{
  background: #348EC4;
  animation: alphaShift 3s infinite;
}

@keyframes hyperShift {
  0% {
    background-color: #F7971E;
  }
  50% {
    background-color: #FFD200;
  }
  100% {
    background-color: #F7971E;
  }
}

@keyframes alphaShift {
  0% {
    background: #56CCF2;
  }
  50% {
    background: #2F80ED;
  }
  100% {
    background: #56CCF2;
  }
}
