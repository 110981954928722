.special-rules{
  background: lightgray;
  border: 1px solid black;
  margin: 5px 0 5px 0;
  padding: 5px;
}

.special-rules-label{
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2px;
}