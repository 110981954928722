.asset-box{
  font-size: 1.1rem;
  line-height: 1.2rem;
  font-family: 'Bowlby One SC', sans-serif;
  cursor: pointer;

  text-align: center;

  background-color:#fff;
  border:solid 2px #000;
  box-shadow:0 6px 6px -6px #000;


  padding: 5px;
  margin: 5px;
  background-image:radial-gradient(circle, #ceffff, #51aaa8);
}