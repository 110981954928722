.agenda-catalog{
  height: fit-content;
  flex: 1;
  max-width: 1100px;
}

.accordion-div{
  border: 2px solid black;
  background: rgba(245, 175, 25, 0.75);
}

.catalog-accordion{
  font-family: 'Open Sans';
}

.faction-select{
  margin: 0.5rem;
}

.catalog-accordion .title{
    border-top: 1px solid black;
}

.catalog-accordion .title:hover{
  background: rgba(241, 39, 17, 0.25);
}

.faction-select{
  background: lightgray;
  border: 1px solid black !important;
  margin-bottom: .5rem; 
}

.agenda-catalog-toolbar{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.create-button-container > .create-list-button{
  margin: .5rem !important;
  font-family: Bowlby One SC !important;
  font-weight: 100 !important;
  font-size: 1em !important;
}