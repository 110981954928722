.select-agenda{
  text-align: center;
}

.all-agendas-box{
  padding: 10px;
}

.all-agendas-box{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.all-agendas-box > a{
  text-decoration: none;
}

.agenda-box{
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-size: 2rem;
  font-family: 'Bowlby One SC', sans-serif;
  color: black;
  /* stroke: 1px solid white; */
  padding:1vmin;

  border:solid 2px #000;
  box-shadow:0 6px 6px -6px #000;
  
  width: 300px;

  padding: 15px;
  margin: 5px;
  background-image:radial-gradient(circle, yellow, orange);
}


