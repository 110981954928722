.rules-index-container{
  background: lightgray;
  border: 2px solid black;
  padding: 10px;
  border-radius: 5px;
  height: fit-content;
}

.rules-accordion{
  font-size: 1rem !important;
  font-family: 'Open Sans';
}
.rules-accordion .title:hover{
  background: lightblue;
}

.rules-accordion > .content{
  border-bottom: 1px solid black;
  padding: .5rem !important;
}

.rules-header{
  font-size: 1.5rem;
  font-family: 'Bowlby One SC', sans-serif;
}