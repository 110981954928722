.admin-page-container{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.admin-sidebar{
  margin: 0 20px 20px 0;
}

.admin-menu{
  border: none !important;
}

.admin-menu .item{
  border-bottom: 1px solid black;
} 

.item:hover{
  background: lightgray !important;
}

.active-admin-menu-item > div{
  background: #45a3d3 !important;
}

.admin-content{
  min-height: 800px;
}