@import url('https://fonts.googleapis.com/css?family=Bangers|Bowlby+One+SC|Open+Sans');
@import './assets/animate.css';

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


