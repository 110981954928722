/* media queries */
/* @media screen and (min-width: 0px) and (max-width: 600px) {
  .stat-card-container { max-width: 100%; } 
}

@media screen and (min-width: 601px) {
  .stat-card-container{ max-width: 800px; } 
} */

.stat-card-container{
  padding: 25px;
  color: black;

  font-size: 1.15rem;
  
  display: flex;
  flex-flow: column wrap;

}

.stat-card-top-row{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.stat-name-row{
  display: flex;
  flex-flow: column nowrap;

}
.stat-block{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}

.model-name{
  font-size: 2rem;
  line-height: 28px;
  font-family: 'Bowlby One SC', sans-serif;
}

.monster-form{
  font-size: 1.25rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;

  /* border-top: 5px dashed black;
  border-bottom: 5px dashed black; */

  position:relative;
  clear:left;
  height:auto;
  text-align:center;
}

.model-subtitle{
  font-size: 1.15rem;
}

.alpha-card{
  background: #348EC4;
}

.hyper-card{
  background: goldenrod;
}

.unit-card{
  background: #a0c945;
}

.building-card{
  background: #cbdbe1
}

.asset-card{
  background: #51aaa8;
}


.stat-label{
  font-size: 0.8rem;
  padding-top: 5px;
  transform: rotate(-90deg);
}

.stat-value{
  font-size: 2rem;
  margin-left: -5px;
}