
.main-header{
  background: lightgrey;
  z-index: 10;

  padding: 5px;
  border-bottom: 2px solid black;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

}

.logo{
  flex: 1;
  font-size: 2.25rem;
  line-height: 2.25rem;
  font-family: 'Bowlby One SC', sans-serif;
  color: black;

  text-align: left;
  margin-left: 5px;
}

.logo-menu-item{
  color: black;
}
.logo-menu-item:hover{
  color: goldenrod;
}

.header-menu{
  flex: 1;
}

.header-menu-items{
  color: black;
  text-decoration: none;
  font-family: 'Bowlby One SC', sans-serif;
  font-size: 1.25rem;
  padding: 8px;
}

.header-menu-items:hover{
  color: goldenrod;
}

.hamburger-spacer{
  width: 2rem;
  height: 3.5rem;
}

.active-item{
  color: #3892c7;
  border: 2px solid #3892c7;
}
