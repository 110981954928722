.dice-input{
  margin-left: .5rem;
  display: flex;
  flex-flow: row nowrap;
}

.input-num{
  width: 60px;
  margin-right: .25rem;
  /* IE fix */
  display: block !important;
}

.input-num > input{
  text-align: center !important;
}

.input-button > i{
  margin: 0 !important;
}