.attack-dice-container{
  display: flex;
  flex-flow: row nowrap;
}

.dice{
  font-weight: bold;
  text-align: center;
  width: 22px;
  border-radius: 5px;
  padding: 2px;

  margin: 0 2px 0 2px;
}

.action-dice{
  border: 2px solid black;
  background: white;
}

.boost-dice{
  border: 2px solid black;
  background: #40a1ef;
}

.power-dice{
  border: 2px solid black;
  background: red;
  color: white;
}