.display-with-remove-button{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.fill-remaining-space{
  flex-grow: 1
}

.remove-button{
  align-self: center;
}

.display-with-remove-button{
  padding: 5px;
  margin: 5px 0 5px 0;
  background: lightgoldenrodyellow;
}