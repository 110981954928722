.current-list-container{
  position: sticky;
  top: -3rem;

  display: flex;
  flex-flow: column;
  flex: 1;
  flex-shrink: 1;

  background: lightgray;
  border: 2px solid black;
  border-radius: 5px;

  padding: 10px;
  margin-left: 1em;
  
  height: fit-content;
  max-width: 400px;
  
}

.list-message > p {
  margin: 0 !important;
}


.current-list-toolbar{
  display: flex;
  flex: 1;
  justify-content: flex-start;
  height: 36px;
}

.current-list-actual{
  display: flex;
  flex-flow: column;
  align-items: center;
}

.current-list-actual > .generic-list-item{
  width: 300px;
}

.list-control-btn-grp{
  margin-left: -2px !important;
  margin-right: 5px !important;
}
.list-control-add-btn{
  border-right: 1px solid grey !important;
}

.clipboard-container{
  width: 100%;
  background: #cbdbe1;
  display: flex;
  flex-flow: column wrap;

  padding: 25px;
  color: black;
  font-size: 1.15rem;
}

#list-text{
  position: absolute;
  left: -9999px;
}