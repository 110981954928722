html,
body {
  overflow: hidden;

  /* Red Skies */
  /* background: #355C7D;  
  background: -webkit-linear-gradient(to bottom, #C06C84, #6C5B7B, #355C7D); 
  background: linear-gradient(to bottom, #C06C84, #6C5B7B, #355C7D);  */
  

  /* Clear Skies */
  background: #2980B9 !important;  
  background: -webkit-linear-gradient(to top, #FFFFFF, #6DD5FA, #2980B9) !important;  
  background: linear-gradient(to top, #FFFFFF, #6DD5FA, #2980B9) !important; 

}

/* Catches most semanitc ui elements */
.ui{
  font-family: 'Open Sans', sans-serif !important;
}

h1, h2, h3, h4, h5{
  font-weight: normal !important;
}
h1{
  font-size: 2rem;
  font-family: 'Bowlby One SC', sans-serif !important;
}
h2{
  font-size: 1.75rem;
  font-family: 'Bowlby One SC', sans-serif !important;
}
h3{
  font-size: 1.25rem;
  font-family: 'Bowlby One SC', sans-serif !important;
}
h4{
  font-size: 1rem;
  font-family: 'Bowlby One SC', sans-serif !important;
}

/* media queries */
@media screen and (min-width: 0px) and (max-width: 800px) {
  .header-menu { display: none; }  /* small screens */
  .logo{ text-align: center; }
}

@media screen and (min-width: 801px) {
  .burger-overlay, .bm-burger-button { display: none; }   /* larger screens */
}

.app {
  border-top:solid 2px #000;
  min-height: 100%;
  height: 100%;
}

#root{
  height: 100%;
}

#page-wrap {
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  z-index: 100;

  /* background-color: #4c94f6; */
  /* background-image:
    url('./assets/images/buildings-skyline.svg'),
    url('./assets/images/new-york-2789701.svg');
  background-size: 140%, 450%;
  background-position: 2% 100%, bottom;
  background-repeat: repeat-x; */
  /* -webkit-animation: slide 20s linear infinite; */
}

.city-skyline{
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  
  background-image:
    url('./assets/images/buildings-skyline.svg'),
    url('./assets/images/new-york-2789701.svg');
  background-size: 140%, 450%;
  background-position: 2% 101%, bottom;
  background-repeat: repeat-x;
  z-index: 0;
  pointer-events: none;
  /* -webkit-animation: slide 20s linear infinite; */
}

@-webkit-keyframes slide {
  from { background-position: 0 bottom; }
    to { background-position: -1000px bottom; }
}

.main-content{
  height: 100%;
  padding: 3em 2em 2em 2em;
  overflow: auto;
  z-index: 10;
  
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; */
}

.hero-tagline{
  font-size: 2rem;
  font-family: 'Bowlby One SC', sans-serif;
}

/* standard bright hover class */
.bright-hover:hover{
  filter: brightness(120%);
}

.form-container{
  background: lightgray;
  padding: 15px;
  /* border-radius: 4px; */
  border: 2px solid black;
}

/* Common List Styles */
.model-list{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.model-list-item{
  flex: 1;
  /* prevent flexbox from changing width */
  /* height: 3.5rem;
  width: 250px;
  min-width: 250px;
  max-width: 250px; */
  height: 3.2rem;
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.item-name{
  flex-grow: 1;
}

.item-label{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.no-tap-zoom, .button, .list-control-btn-grp{
  touch-action: manipulation;
}

.bold-link{
  font-weight: bold;
}

.ui.modal > .close {
  color: #FFFFFF !important;
}

/* used to group list items and buttons */
.generic-list-item{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.generic-list-item > .ui.icon.button{
  height: 3em;
}


/* for list messages, not working in component css file. Am tired, will figure out later */
.list-message{
  margin: 0.3em !important;
  padding: .5em !important;
}

.list-message-icon{
  font-size: 2em !important;
}