.stat-block-spd, .stat-block-def{
  font-weight: bold;
  padding: 5px;
  border: 1px solid black;
  background: lightgray;

  display: flex;
  flex-flow: row nowrap;
}

.stat-block-def{
  margin-left: 5px; /* looks weird on mobile */

}

.stat-block-hm{
  margin-left: 3px !important;
  transform: rotate(45deg);
}

.unit-block{
  display: flex;
  flex-flow: row nowrap;
}
.unit-cost, .unit-elite{
  padding: 5px;
  background: lightgray;
  border: 1px solid black;
  width: max-content;
  margin-right: 1em;
}