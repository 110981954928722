.dice-odds-container{
  background: lightgray;
  border: 2px solid black;
  /* width: max-content !important; */
  padding: 10px;
  border-radius: 5px;

  display: flex;
  flex-flow: row nowrap;
  height: fit-content;
}

.odds-input-row{
  line-height: 1em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: .1rem;
}

.odds-checkbox-row{
  padding: .5em;
}

.all-inputs{
  width: max-content;
}