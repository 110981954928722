.attack-info{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

}

.attack-type{
  font-family: 'Bowlby One SC', sans-serif;
  font-size: 1.5rem;
}

.attack-box{
  background: lightgray;
  border: 1px solid black;
  margin: 5px 0 5px 0;
  padding: .5rem;
  width: 100%;
}

.attack-rules{
  margin-top: 5px;
  border-top: 1px solid black;
}